<template>
  <div class="home">
    <div
      :class="{ loading: true, 'loading-active': loaderVisible }"
      v-if="loaderActive"
    >
      <img width="350" src="@/assets/ZE_Loading.gif" />
    </div>
    <NavBar
      style="z-index: 1500; position: fixed; left: 0px; right: 0px"
      :user="user"
      :isLoggedIn="isLoggedIn"
      @logout="logout()"
      :color="scrollPosition > 20 ? 'white' : 'transparent'"
    ></NavBar>
    <div class="landing" id="home">
      <div class="landing-background">
        <div class="landing-background-color"></div>
      </div>
      <div class="landing-text row">
        <div class="landing-logo"></div>
        <div class="landing-header">Zelena Energija</div>
        <div class="landing-subheader">
          Iskoristite potencijal sunca na najbolji nacin!
        </div>
      </div>
    </div>

    <div class="cards">
      <div class="container">
        <div class="row justify-center content-center">
          <div class="col-12 col-md-6 col-lg-4 pb-5">
            <div class="card text-left mx-auto">
              <img class="card-img-top" src="@/assets/efikasnost.png" alt="" />
              <div class="card-body">
                <h4 class="card-title">Iskoristite moć sunca!</h4>
                <p class="card-text">
                  Na najbolji način iskoristite snagu sunca pretvarajući je u
                  električnu energiju za vaše domaćinstvo ili više!
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 pb-5">
            <div class="card text-left mx-auto">
              <img
                class="card-img-top"
                src="@/assets/produktivnost.png"
                alt=""
              />
              <div class="card-body">
                <h4 class="card-title">Uštedite novac!</h4>
                <p class="card-text">
                  Dobijate velike mesečne račune za struju? Sunčava energija vam
                  može znatno ublažiti iste!
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-4 pb-5">
            <div class="card text-left mx-auto">
              <img class="card-img-top" src="@/assets/panel.png" alt="" />
              <div class="card-body">
                <h4 class="card-title">Solarni paneli!</h4>
                <p class="card-text">
                  Instalacijom <b>Solarnih Panela</b> ublažićete troškove
                  struje, i doprineti očuvanju zelenila!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="services" id="services">
      <div class="tab-header">
        <h1 style="color: black">Usluge koje nudimo</h1>
        <div class="line">_______</div>
        <h6>Sta sve možemo uciniti za vas?</h6>
      </div>
      <div class="container container-sm">
        <div class="row justify-center">
          <div class="col-12 col-lg-6 pb-5 mx-auto">
            <div class="card text-left mx-auto">
              <div class="card-body">
                <h4 class="card-title">Ugradnja solarnih panela</h4>
                <p class="card-text">
                  Instalacijom <b>Solarnih Panela</b> ublažićete troškove struje
                  čak do 80%, i znatno doprineti očuvanju zelenila!
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 pb-5 mx-auto">
            <div class="card text-left mx-auto">
              <div class="card-body">
                <h4 class="card-title">Prodaja solarne opreme</h4>
                <p class="card-text">
                  Naš širok asortiman <b>visoko kvalitetne</b> opreme je pravi
                  izbor za Vas i Vašu elektranu!
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 pb-5 mx-auto">
            <div class="card text-left mx-auto">
              <div class="card-body">
                <h4 class="card-title">Izvodjenje elektro instalacija</h4>
                <p class="card-text">
                  Sa znatnim iskustvom od preko <b>15 godina</b> u struci!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-image" id="references">
      <div class="p-5">
        <div class="tab-header">
          <h1 style="color: white">Reference</h1>
          <div style="color: #8dd06c" class="line">_______</div>
          <h6 style="color: #8dd06c">Neki od prethodnih projekata:</h6>
        </div>
        <div class="row pb-5 pr-lg-5">
          <div class="col-12 col-lg-6 image p-5">
            <div class="text-img img-1">
              <div class="text">
                <h1>Sistem za domaćinstvo 12kW</h1>
                <p></p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 image p-5">
            <div class="text-img img-2">
              <div class="text">
                <h1>Sistem za domaćinstvo 10kW</h1>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact" id="contact">
      <div class="tab-header text-center">
        <h1 style="color: black">Kontaktirajte nas</h1>
        <div style="color: #115d11 !important" class="line">_______</div>
        <h6 style="color: #115d11 !important; text-transform: none">
          Imate li neko pitanje? Pošaljite upit!
        </h6>
      </div>
      <div class="row contact-form mx-auto justify-center">
        <div class="col mx-auto justify-center">
          <b-form-group class="mx-auto" label="Ime i Prezime:">
            <b-form-input
              v-model="form.name"
              placeholder="Ime Prezime"
              :class="{ error: form.nameError }"
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row contact-form mx-auto justify-center">
        <div class="col mx-auto justify-center">
          <b-form-group class="mx-auto" label="Email ili Broj Telefona:">
            <b-form-input
              v-model="form.email"
              placeholder="Email ili Broj Telefona"
              :class="{ error: form.emailError }"
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row contact-form mx-auto justify-center">
        <div class="col mx-auto justify-center">
          <b-form-group class="mx-auto" label="Poruka:">
            <b-form-textarea
              v-model="form.message"
              :class="{ error: form.messageError }"
              placeholder="..."
              rows="4"
              max-rows="6"
              required
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <div
        v-if="form.error"
        class="row contact-form error-msg mx-auto justify-center mb-3"
      >
        <div class="col text-center">
          <a class="">{{ form.error }}</a>
        </div>
      </div>
      <div
        v-if="form.success"
        class="row contact-form success-msg mx-auto justify-center mb-3"
      >
        <div class="col text-center">
          <a class="">{{ form.success }}</a>
        </div>
      </div>
      <div class="row contact-form mx-auto justify-end">
        <div class="col mx-auto justify-end">
          <b-button variant="success" @click="sendForm()">Pošalji!</b-button>
        </div>
      </div>
    </div>
    <div class="footer">
      <b-container>
        <b-row>
          <b-col cols="12" md="6"
            ><iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.985990431689!2d19.10357317676359!3d45.771471012883836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475cb58a5533337f%3A0xf0589c48c5479961!2z0JzQsNGA0LjRmNC1INCR0YPRgNGB0LDRmyAyMywg0KHQvtC80LHQvtGA!5e0!3m2!1ssr!2srs!4v1719520736795!5m2!1ssr!2srs"
              width="100%"
              height="300"
              style="
                border: 0;
                border-radius: 10px;
                filter: invert(1) hue-rotate(196deg);
              "
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </b-col>
          <b-col cols="12" md="6" class="contactf">
            <h2>Kontakt</h2>
            <b-row>
              <b-col cols="12" style="margin-bottom: 10px">
                <a style="font-size: 20px" href="tel:+381600285701">
                  <b-icon
                    style="font-size: 25px; margin-top: -3px"
                    icon="phone"
                  ></b-icon
                  >+381 60 028 5701</a
                >
              </b-col>
              <b-col cols="12" style="margin-bottom: 10px">
                <a
                  style="font-size: 20px"
                  href="mailto:zzelenaenergija@gmail.com"
                >
                  <b-icon
                    style="font-size: 25px; margin-top: -3px"
                    icon="envelope-open"
                  ></b-icon>
                  zzelenaenergija@gmail.com</a
                >
              </b-col>
              <b-col cols="12" style="margin-bottom: 10px">
                <a
                  style="font-size: 20px"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/zzelenaenergijars"
                >
                  <b-icon
                    style="font-size: 25px; margin-top: -3px"
                    icon="facebook"
                  ></b-icon>
                  Zelena Energija
                </a>
              </b-col>
              <b-col cols="12">
                <a
                  href="https://www.instagram.com/zelena_energija_srbija/"
                  style="font-size: 20px"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b-icon
                    style="font-size: 25px; margin-top: -3px"
                    icon="instagram"
                  ></b-icon>
                  zelenaenergija</a
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="margin-top: 20px"
            ><h6>Copyright © Zelena Energija 2024</h6></b-col
          >
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import AuthService from "@/services/AuthService";
import SubmissionsService from "@/services/SubmissionsService";
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export default {
  name: "HomeView",
  components: {
    NavBar,
  },
  data() {
    return {
      scrollPosition: null,
      form: {
        name: "",
        email: "",
        message: "",
        error: "",
        success: "",
        nameError: false,
        emailError: false,
        messageError: false,
      },
      autoModal: true,
      user: null,
      isLoggedIn: false,
      loaderActive: false,
      loaderVisible: false,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    logout() {
      this.$store.dispatch("logout");
      this.isLoggedIn = false;
    },
    sendForm() {
      this.form.nameError = false;
      this.form.messageError = false;
      this.form.emailError = false;
      this.form.error = "";
      this.form.success = "";
      if (this.form.name.length < 3) {
        this.form.nameError = true;
        this.form.error = "Ime i Prezime mora biti popunjeno!";
        return;
      }
      if (this.form.email.length < 3) {
        this.form.emailError = true;
        this.form.error = "EMail mora biti popunjen!";
        return;
      }
      if (!validateEmail(this.form.email)) {
        this.form.emailError = true;
        this.form.error = "EMail nije ispravan!";
        return;
      }
      if (this.form.message.length < 5) {
        this.form.messageError = true;
        this.form.error = "Poruka mora biti duža od 5 karaktera!";
        return;
      }
      this.loaderActive = true;
      this.loaderVisible = true;
      SubmissionsService.sendMessage(this.form)
        .then(() => {
          this.form = {
            success:
              "Uspesno ste poslali poruku! Odgovor će te dobiti na EMail u kratkom roku!",
          };
        })
        .catch(() => {
          this.form = {
            error:
              "Greška prilikom slanja prijave za Subvenciju, pokušajte ponovo kasnije!",
          };
        });
      setTimeout(() => {
        this.loaderVisible = false;
        setTimeout(() => {
          this.loaderActive = false;
        }, 250);
      }, 3000);
    },
  },
  async mounted() {
    window.addEventListener("scroll", this.updateScroll);
    this.isLoggedIn = !!this.$store.getters.isLoggedIn;
    if (this.isLoggedIn) {
      var data = await AuthService.userDetails().catch((err) => {
        this.$store.dispatch("logout");
        this.isLoggedIn = false;
        if (err.response.status == 403) {
          this.$router.push({
            path: "/",
          });
        } else {
          this.$router.push({
            path: "/",
          });
        }
      });
      this.user = data;
    }
  },
};
</script>
<style lang="scss">
* {
  scroll-behavior: smooth !important;
}
.footer {
  background-color: rgb(20, 20, 20);
  height: auto;
  padding-top: 50px;
  padding-bottom: 10px;
  color: white;
  position: relative;
  width: 100%;
  background-color: black;
  color: rgb(129, 139, 130);
  padding: 50px;
  z-index: 1000;
}
.footer {
  .contactf {
    * {
      text-align: left;
    }
    h6 {
      text-transform: uppercase;
      font-weight: bold;
      color: rgb(82, 82, 82);
    }
    a {
      color: rgb(167, 167, 167);
    }
  }
}
.landing {
  height: 80vh;
  .landing-background,
  .landing-background-color {
    position: fixed;
    left: -1vw;
    top: -1vh;
    width: 102vw;
    height: 102vh;
    background-color: #115d33;
    background-image: linear-gradient(60deg, #115d33, #228b22);
    background-position: 100% 10%;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(4px);
  }
  .landing-background {
    background-image: url("@/assets/img/DJI_0313.JPG");
  }
  .landing-background-color {
    opacity: 0.7;
    top: 0;
  }
  .landing-text {
    color: white;
    position: absolute;
    top: 20vh;
    left: 20vw;
    width: calc(130px + 40vw);
    height: calc(40px + 8vw);
    padding: 20px;
    border-left: 2px solid white;
    text-wrap: nowrap;
    .landing-logo {
      background-image: url("@/assets/logo.png");
      background-position: 0% 0%;
      background-size: contain;
      background-repeat: no-repeat;
      height: calc(10px + 7vw);
      width: calc(10px + 7vw);
      position: absolute;
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222) hue-rotate(30deg);
    }
    .landing-header {
      margin-left: calc(20px + 7vw);
      margin-top: -10px;
      color: white;
      font-weight: bold;
      text-align: left;
      font-size: calc(4vw + 10px);
      filter: drop-shadow(2px 2px 5px #222);
    }
    .landing-subheader {
      font-size: calc(0.2vw + 10px);
      text-align: left;
      margin-left: calc(20px + 7vw);
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222);
    }
  }
}

.cards {
  z-index: 2;
  position: relative;
  top: -50px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  .container {
    align-items: center;
    text-align: center;
  }

  .card {
    max-width: 400px;
    border: none;
    border-bottom: 1px solid green;
    border-radius: 5px;
    //box-shadow: 8px 11px 20px 5px #228b22;
    background-color: transparent;
    img {
      width: 150px;
      height: 150px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      filter: drop-shadow(2px 2px 2px #8dd06c) grayscale(0) invert(1)
        hue-rotate(201deg);
    }
    .card-text {
      color: #115d33;
      font-weight: light;
    }
  }
}

.tab-header {
  color: #228b22;
  text-align: center;
  padding-bottom: 30px;
}

.services {
  position: relative;
  margin-top: -51px;
  background-color: white;
  padding: 20px;
  padding-top: 100px;
  .card {
    color: rgb(0, 0, 0);
    background-color: #228b2211;
    margin: auto;
    width: 100%;
    height: 140px;
    .card-text {
      color: #115d33;
    }
  }
}

.text-image {
  z-index: 1;
  margin-top: -51px;
  padding-top: 100px;
  position: relative;
  padding-bottom: 50px;
  .img-1 {
    background-image: url("@/assets/img/DJI_0140.jpg");
  }
  .img-2 {
    background-image: url("@/assets/img/DJI_0006.jpg");
  }

  .text-img {
    background-size: cover;
    width: calc(100% + 30px);
    margin-left: -15px;
    height: 500px;
    padding: 0px;
    z-index: 100;
    box-shadow: inset 00px -140px 120px -10px black;
  }
  .text {
    z-index: 100;
    position: absolute;
    bottom: 120px;
    text-align: left;
    padding: 40px;
    height: 100px;
    color: white;
    h1 {
      font-size: calc(12px + 1vw);
    }
    p {
      font-size: calc(10px + 0.2vw);
    }
  }
}

.contact {
  position: relative;
  background-color: white;
  text-align: center;
  padding: 40px;
  padding-top: 100px;
  padding-bottom: 120px;
  .contact-form {
    text-align: left;
    max-width: 500px;
  }
}

.p-lg-6 {
  padding: $spacer * 10 !important;
}
</style>
