<template>
  <div>
    <b-btn
      variant="success"
      v-b-toggle.sidebar-1
      style="position: fixed; left: 10px; top: 10px"
      ><b-icon
        icon="list"
        style="color: white"
        :class="{ 'mr-1': notifications > 0 }"
      ></b-icon>

      <b-badge variant="danger" v-if="notifications > 0">{{
        notifications
      }}</b-badge></b-btn
    >
    <b-sidebar
      id="sidebar-1"
      v-if="this.tabs != undefined"
      style="z-index: 5000"
      title="Zelena Energija"
      text-variant="light"
      backdrop
      visible
      bg-variant="success"
      shadow
    >
      <div class="px-3 py-2">
        <h6>Welcome, {{ user.firstName }}</h6>
        <p style="font-size: 10px">
          You have come to the control panel of the Website!
        </p>
        <hr />
        <b-btn
          v-for="(tab, i) in filteredTabList"
          :key="i"
          variant="outline-light"
          :disabled="$route.name == tab.routeName"
          style="width: 100%"
          class="mb-2 text-left"
          :to="tab.url"
        >
          <b-icon :icon="tab.icon"></b-icon>
          {{ tab.name }}
        </b-btn>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: {
    tabs: {
      type: Array,
      default: () => {
        [];
      },
    },
    user: {
      type: Object,
      default: () => {
        return { firstName: "", permissions: [] };
      },
    },
    notifications: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      sidebarOpen: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    checkPermission(permissions, permission) {
      if (permissions.includes("*") || permissions.includes("*.*")) {
        return true;
      }
      if (permissions.includes(permission)) {
        return true;
      }
      if (permission.includes(".")) {
        let strings = permission.split(".");
        if (permissions.includes(strings[0] + ".*")) {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    filteredTabList() {
      if (this.tabs) {
        return this.tabs.filter(
          (el) =>
            (el.permission != null &&
              this.checkPermission(this.user.permissions, el.permission)) ||
            !el.permission
        );
      } else {
        return [];
      }
    },
  },
};
</script>
<style lang="scss">
.b-sidebar-outer {
  transition: all 100ms;
  .b-sidebar {
    transition: all 100ms;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 280px;
    padding-top: 20px;
    height: 100% !important;
    background-color: rgba(3, 112, 3, 0.527) !important;
    backdrop-filter: blur(4px);
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
</style>
